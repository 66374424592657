<template>
  <div class="flex flex-col gap-2 md:w-fit md:flex-row md:gap-5">
    <div v-for="(button, idx) in data" :key="idx" class="!max-w-full flex-1">
      <Button
        :label="button.title && button.title"
        :to="
          button.pageLink !== null
            ? button.anchorLink
              ? `${localePathByType(
                  button.pageLink?.__typename as string,
                  button.pageLink?.slug as string,
                  button.pageLink?.__typename === ('ArticleRecord' as string) &&
                    button.pageLink?.category.slug
                )}${button.anchorLink}`
              : localePathByType(
                  button.pageLink?.__typename as string,
                  button.pageLink?.slug as string,
                  button.pageLink?.__typename === ('ArticleRecord' as string) &&
                    button.pageLink?.category.slug
                )
            : button.externalLink
        "
        :target="button.externalLink ? '_blank' : undefined"
        :variant="button.primary ? 'solid' : 'outline'"
        :size="size as ButtonProps['size']"
        :class="data?.length === 2 && '!w-full'"
        :color="primaryOrange ? 'orange' : 'black'"
        type="button"
        @click="handleClick(button)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { CallToActionFragment } from '~/types/generated'
import { ButtonProps } from '~/components/action/Button.vue'

gsap.registerPlugin(ScrollToPlugin)

const { localePathByType } = useLocalePathByType()

defineProps({
  data: {
    type: Array as PropType<CallToActionFragment[]>
  },
  primaryOrange: {
    type: Boolean,
    default: true
  },
  size: {
    type: String,
    default: 'lg'
  }
})

function handleClick(button: CallToActionFragment) {
  if (button.anchorLink) {
    gsap.to(window, {
      duration: 2,
      scrollTo: button.anchorLink,
      ease: 'power3.inOut'
    })
  }
}
</script>
